import { requestedAdEvent } from 'libs/common/event-tracker/events'

import useTracking from 'hooks/useTracking/useTracking'
import useSession from 'hooks/useSession'
import useSystemConfiguration from 'hooks/useSystemConfiguration'
import useFeatureSwitch from 'hooks/useFeatureSwitch'

function useRequestedAdLogging(placementId: string) {
  const { screen } = useSession()
  const { userCountry: countryCode } = useSystemConfiguration() || {}

  const isRequestedAdLoggingEnabled = useFeatureSwitch('web_ads_requested_ad_logging')

  const { track } = useTracking()

  const onRequest = () => {
    if (!isRequestedAdLoggingEnabled || !countryCode) return

    track(
      requestedAdEvent({
        placementId,
        screen,
        countryCode,
      }),
    )
  }

  return { onRequest }
}

export default useRequestedAdLogging
