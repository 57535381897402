import { WalletBalanceDto, InvoiceDto, WalletInvoicesDto, InvoiceLineDto } from 'types/dtos'
import { InvoiceLineModel, InvoiceModel, InvoicesModel, WalletBalanceModel } from 'types/models'

import { transformCurrencyAmountDto } from './currency-amount'

export const transformWalletBalanceDto = ({
  available_amount,
  escrow_amount,
}: WalletBalanceDto): WalletBalanceModel => ({
  pendingAmount: transformCurrencyAmountDto(escrow_amount),
  availableAmount: transformCurrencyAmountDto(available_amount),
})

export const transformInvoiceDto = (invoice: InvoiceDto): InvoiceModel => ({
  invoiceSeries: invoice.invoice_series,
  invoiceNo: invoice.invoice_no,
  invoiceDate: invoice.invoice_date,
  id: invoice.id,
})

export const transformInvoiceLineDto = (line: InvoiceLineDto): InvoiceLineModel => {
  const { id, amount, date, title, subtitle, type, entity_id, entity_type } = line

  return {
    id,
    amount: transformCurrencyAmountDto(amount),
    date,
    title,
    subtitle,
    type,
    entityId: entity_id,
    entityType: entity_type,
  }
}

export const transformInvoiceLinesDto = (lines: Array<InvoiceLineDto>): Array<InvoiceLineModel> =>
  lines.map(transformInvoiceLineDto)

export const transformWalletInvoices = (walletInvoices: WalletInvoicesDto): InvoicesModel => ({
  invoiceLines: transformInvoiceLinesDto(walletInvoices.invoice_lines),
  startBalance: transformCurrencyAmountDto(walletInvoices.previous_balance),
  endBalance: transformCurrencyAmountDto(walletInvoices.balance),
  startingDate: walletInvoices.starting_date,
  history: walletInvoices.history?.map(history => ({
    title: history.title,
    year: history.year,
    month: history.month,
    balance: transformCurrencyAmountDto(history.balance),
  })),
  taxpayerRestricted: walletInvoices.taxpayer_restricted,
})
