import { useMemo } from 'react'

import useQueryParams from 'hooks/useQueryParams'
import { CatalogDto } from 'types/dtos'
import { getCatalogInitializersParamsFromUrl } from 'pages/Catalog/utils/utils'
import useLocation from 'hooks/useLocation'

const findCatalogs = (
  catalogs: Array<CatalogDto>,
  ids: Array<number>,
  result: Array<CatalogDto> = [],
): Array<CatalogDto> | undefined => {
  if (ids.length === result.length) return result

  catalogs.forEach(catalog => {
    if (ids.includes(catalog.id)) {
      result.push(catalog)
    }

    findCatalogs(catalog.catalogs, ids, result)
  })

  return result
}

const useSelectedCatalogs = (catalogTree: Array<CatalogDto>): Array<CatalogDto> => {
  const queryParams = useQueryParams()
  const { relativeUrl } = useLocation()

  let { catalog } = getCatalogInitializersParamsFromUrl(relativeUrl, queryParams)
  catalog = queryParams.catalog || catalog

  const selectedIdsString = Array.isArray(catalog) ? catalog : [catalog]
  const selectedIds: Array<number> = selectedIdsString
    .filter(item => !!item)
    .map(item => Number(item))

  const selectedCatalogs = useMemo<Array<CatalogDto>>(() => {
    return findCatalogs(catalogTree, selectedIds) || []
  }, [catalogTree, selectedIds])

  return selectedCatalogs
}

export default useSelectedCatalogs
