'use client'

import { Badge, Divider, Label } from '@vinted/web-ui'
import { useSelector } from 'react-redux'

import SeparatedList from 'components/SeparatedList'
import AccountSwitch from 'components/Header/UserMenu/AccountSwitch'
import useFeatureSwitch from 'hooks/useFeatureSwitch'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'

import {
  DONATIONS_SETTINGS_URL,
  GO_TO_WALLET_URL,
  MEMBER_PROFILE_URL,
  PROFILE_SETTINGS_URL,
  REFERRALS_URL,
  ROOT_URL,
  USER_PERSONALIZATION_SIZES_URL,
  WALLET_INVOICES_URL,
  MY_ORDERS_URL,
} from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { OrderType } from 'constants/my-orders'
import { logoutUser } from 'data/api/authentication/requests'
import { donationsClickEvent } from 'libs/common/event-tracker/events'
import { navigateToPage } from 'libs/utils/window'
import useCookie from 'libs/common/cookie-manager/hooks/useCookie'

import { getUser } from 'state/session/selectors'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'
import { getShowFundraiser } from 'state/selectors'
import useShouldShowDarkModeAdoption from 'hooks/useShouldShowDarkModeBanner'

import AccountLink from './AccountLink'

const AccountLinks = () => {
  const translate = useTranslate()
  const { track } = useTracking()
  const cookies = useCookie()

  // TODO: 'payments' is a "special" (hard-coded) feature switch, which must be removed
  const isPaymentsEnabled = useSelector(getIsFeatureSwitchEnabled('payments'))
  const shouldShowMyOrdersNewBadge = useFeatureSwitch('my_orders_new_badge_web')
  const showFundraiser = useSelector(getShowFundraiser)
  const isReferralsEnabled = useSelector(getIsFeatureSwitchEnabled('referrals'))
  const user = useSelector(getUser)
  const shouldShowDarkModeAdoption = useShouldShowDarkModeAdoption()
  const isImpersonatingBusiness = user?.impersonating_business

  const handleLogoutClick = async () => {
    const response = await logoutUser({ cookies })

    if (response) navigateToPage(ROOT_URL)
  }

  const handleFundraiserClick = () => {
    track(
      donationsClickEvent({
        target: ClickableElement.EnterDonationsFromWebMenu,
      }),
    )
  }

  const renderSettingsSuffix = () => {
    if (!shouldShowDarkModeAdoption) return null

    return <Badge theme="primary" content={translate('common.new_badge')} />
  }

  if (!user) return null

  return (
    <>
      <Label styling={Label.Styling.Wide} text={translate('header.user_menu.account.title')} />
      <SeparatedList separator={<Divider />}>
        <AccountLink
          title={translate('header.user_menu.account.items.profile.title')}
          url={MEMBER_PROFILE_URL(user.id)}
        />
        {!isImpersonatingBusiness && (
          <AccountLink
            title={translate('header.user_menu.account.items.settings.title')}
            url={PROFILE_SETTINGS_URL}
            suffix={renderSettingsSuffix()}
            testId="settings-account-link"
          />
        )}
        {!isImpersonatingBusiness && (
          <AccountLink
            title={translate('header.user_menu.account.items.personalization.title')}
            url={USER_PERSONALIZATION_SIZES_URL}
          />
        )}
        {isPaymentsEnabled && !isImpersonatingBusiness && (
          <AccountLink
            title={translate('header.user_menu.account.items.wallet.title')}
            url={GO_TO_WALLET_URL}
          />
        )}
        <AccountLink
          title={translate('header.user_menu.account.items.my_orders.title')}
          url={MY_ORDERS_URL({
            type: OrderType.Sold,
          })}
          suffix={
            shouldShowMyOrdersNewBadge && (
              <Badge theme="primary" content={translate('common.new_badge')} />
            )
          }
          testId="my-orders-account-link"
        />
        {showFundraiser && !isImpersonatingBusiness && (
          <AccountLink
            title={translate('header.user_menu.account.items.donations.title')}
            url={DONATIONS_SETTINGS_URL}
            onClick={handleFundraiserClick}
          />
        )}
        {!isPaymentsEnabled && !isImpersonatingBusiness && (
          <AccountLink
            title={translate('header.user_menu.account.items.invoices.title')}
            url={WALLET_INVOICES_URL}
          />
        )}
        {isReferralsEnabled && !isImpersonatingBusiness && (
          <AccountLink
            title={translate('header.user_menu.account.items.referrals.title')}
            url={REFERRALS_URL}
          />
        )}
        {user?.manageable_accounts?.length && <AccountSwitch />}
        <AccountLink
          title={translate('header.user_menu.account.items.logout.title')}
          onClick={handleLogoutClick}
        />
      </SeparatedList>
    </>
  )
}

export default AccountLinks
