import { FilterModel } from 'types/models'

export enum CatalogFeature {
  MobileFilters = 'mobile_filters',
  Filters = 'filters',
  Items = 'items',
}

export const catalogInsertPositions = {
  closetPromo: {
    wide: {
      first: 8,
      distance: 24,
    },
    desktops: {
      first: 6,
      distance: 24,
    },
    tablets: {
      first: 6,
      distance: 24,
    },
    phones: {
      first: 6,
      distance: 24,
    },
  },
  activationBanner: {
    wide: {
      first: 8,
      distance: 20,
    },
    desktops: {
      first: 6,
      distance: 18,
    },
    tablets: {
      first: 6,
      distance: 18,
    },
    phones: {
      first: 6,
      distance: 18,
    },
  },
  promotionalListingBanner: {
    wide: {
      first: 16,
      distance: 18,
    },
    desktops: {
      first: 18,
      distance: 18,
    },
    tablets: {
      first: 18,
      distance: 18,
    },
    phones: {
      first: 18,
      distance: 18,
    },
  },
  ivsGuidelineBanner: {
    wide: {
      first: 12,
      distance: 12,
    },
    desktops: {
      first: 12,
      distance: 12,
    },
    tablets: {
      first: 12,
      distance: 12,
    },
    phones: {
      first: 12,
      distance: 12,
    },
  },
  ads: {
    wide: {
      first: 20,
      distance: 24,
    },
    phones: {
      first: 18,
      distance: 24,
    },
  },
}

export enum ItemUiView {
  UserItems = 'userItems',
  SimilarItems = 'similarItems',
  CatalogItems = 'catalogItems',
}

export const PER_PAGE = 20
export const CATALOG_PER_PAGE = 96
export const CATALOG_FETCH_ITEM_DEBOUNCE_AMOUNT = 100
export const MAX_OWNER_ITEMS_PER_PAGE = 24

export const FILTER_PRICE_FROM: keyof FilterModel = 'priceFrom'
export const FILTER_PRICE_TO: keyof FilterModel = 'priceTo'

export const CATALOG_FETCH_DEBOUNCE_AMOUNT = 500
export const CATALOG_FILTERS_SEARCH_DEBOUNCE_AMOUNT = 300
export const SCROLL_TO_TOP_DELAY = 1000
export const CATALOG_FILTER_CHANGE_DEBOUNCE_AMOUNT = 300

export const CATALOG_ROUTE_REGEX = /^\/catalog\/(\d+).*$/
export const BRAND_ROUTE_REGEX = /^\/brand\/(\d+).*$/

export const CATALOG_MAIN_ROUTE = '/catalog'
export const CATALOG_BRAND_ROUTE = '/brand'

export const CURRENCY_FALLBACK = 'EUR'
export const LOCALE_FALLBACK = 'en_GB'
