'use client'

import { useState } from 'react'
import { Cell, Spacer } from '@vinted/web-ui'
import classNames from 'classnames'

import { AuthModal } from 'components/Auth'
import HeaderHelpButton from 'components/HeaderHelpButton'
import HeaderLogo from 'components/HeaderLogo'
import LanguageSelector from 'components/LanguageSelector'
import { SearchSubscribeButton } from 'components/SavedSearches'
import AppBanner from 'components/AppBanner'

import { AccessChannel } from 'constants/index'
import { CatalogDto, LanguageDto } from 'types/dtos'
import { GUIDE_ENTRIES_URL } from 'constants/routes'
import { urlWithParams } from 'libs/utils/url'
import { getIsInCatalog } from 'pages/Catalog/utils/utils'

import useLocation from 'hooks/useLocation'
import useIsInPage from 'hooks/useIsInPage'
import useSession from 'hooks/useSession/useSession'
import useAbTest from 'hooks/useAbTest/useAbTest'

import CatalogNavigation from './CatalogNavigation'
import HeaderLoginButton from './HeaderLoginButton'
import HeaderToggleMobileMenu from './HeaderToggleMobileMenu'
import HeaderUploadItem from './HeaderUploadItem'
import HeaderUserActions from './HeaderUserActions'
import SearchBar from './SearchBar'
import SideNavigation from './SideNavigation'
import UserMenu from './UserMenu'

type Props = {
  languages: Array<LanguageDto>
  catalogTree: Array<CatalogDto>
  searchType?: ComponentProps<typeof SearchBar>['searchType']
  impressumUrl: string | null
  isBusinessAccountLinksVisible: boolean
  isOurPlatformVisible: boolean
}

const Header = ({
  languages,
  catalogTree,
  searchType,
  impressumUrl,
  isBusinessAccountLinksVisible,
  isOurPlatformVisible,
}: Props) => {
  const { relativeUrl, searchParams } = useLocation()
  const userId = useSession().user?.id
  const webToAppAbTest = useAbTest({ abTestName: 'web_2_app_conversion_v1' })
  const disableSearchSaving = searchParams?.disable_search_saving
  const isInCatalog = getIsInCatalog(relativeUrl)
  const isInRootPage = useIsInPage('root')

  const showSearchSubscribeButton = isInCatalog && userId && !disableSearchSaving

  const [isSideNavigationOpen, setIsSideNavigationOpen] = useState(false)

  function toggleSideNavigation() {
    setIsSideNavigationOpen(prevState => !prevState)
  }

  function renderAppBannerAbTest() {
    if (webToAppAbTest?.variant === 'on' && isInRootPage) {
      return <AppBanner isHeader />
    }

    return null
  }

  function renderHeaderHelpButton() {
    return (
      <HeaderHelpButton
        url={urlWithParams(GUIDE_ENTRIES_URL, {
          access_channel: AccessChannel.VintedGuide,
        })}
      />
    )
  }

  return (
    <>
      {/* TODO: remove `js-header` which is used in the <Advertisement /> */}
      <div className={classNames('l-header js-header', { 'is-active': isSideNavigationOpen })}>
        {renderAppBannerAbTest()}
        <header className="l-header__main">
          <div className="container u-flexbox u-align-items-center u-fill-height">
            <div className="u-flexbox u-align-items-center u-fill-width">
              <HeaderLogo />
              <div className="u-desktops-only u-fill-width u-position-relative u-z-index-notification u-ui-margin-right-x3-large">
                <SearchBar
                  catalogTree={catalogTree}
                  isInCatalog={isInCatalog}
                  searchType={searchType}
                />
              </div>

              <div className="u-flexbox u-margin-left-auto u-align-items-center u-position-relative u-z-index-notification">
                {userId && (
                  <div className="u-flexbox u-align-items-center">
                    <HeaderUserActions />
                  </div>
                )}

                <div className="u-desktops-only u-flexbox u-align-items-center">
                  {userId && (
                    <div className="u-position-relative u-ui-padding-left-regular u-ui-padding-right-x-large">
                      <UserMenu />
                    </div>
                  )}
                  {!userId && (
                    <>
                      <HeaderLoginButton />
                      <Spacer orientation={Spacer.Orientation.Vertical} />
                    </>
                  )}
                  <HeaderUploadItem />

                  {renderHeaderHelpButton()}
                  <div className="u-position-relative">
                    <LanguageSelector initialLanguages={languages} />
                  </div>
                </div>
              </div>

              <div className="u-mobiles-only">
                <HeaderToggleMobileMenu
                  isActive={isSideNavigationOpen}
                  onToggle={toggleSideNavigation}
                />
              </div>
            </div>
          </div>
        </header>

        <div className="l-header__navigation">
          {isSideNavigationOpen && (
            <div className="u-mobiles-only">
              <SideNavigation
                catalogTree={catalogTree}
                impressumUrl={impressumUrl}
                isBusinessAccountLinksVisible={isBusinessAccountLinksVisible}
              />
            </div>
          )}

          <div className="u-desktops-only">
            <div className="container">
              <CatalogNavigation
                tree={catalogTree}
                impressumUrl={impressumUrl}
                isBusinessAccountLinksVisible={isBusinessAccountLinksVisible}
                isOurPlatformVisible={isOurPlatformVisible}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="l-search">
        <Cell styling={Cell.Styling.Tight}>
          <div className="u-ui-padding-vertical-regular">
            <div className="container">
              <div className="u-flexbox">
                <div className="u-fill-width">
                  <SearchBar
                    catalogTree={catalogTree}
                    isInCatalog={isInCatalog}
                    searchType={searchType}
                  />
                </div>

                {showSearchSubscribeButton && (
                  <div className="u-phones-only u-ui-margin-left-medium">
                    <SearchSubscribeButton />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Cell>
      </div>

      {!userId && <AuthModal />}
    </>
  )
}

export default Header
