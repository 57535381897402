import { AccountStaffDto, InvitedStaffDto, ManageableAccountDto } from 'types/dtos'
import { AccountStaffModel, InvitedStaff, ManageableAccount } from 'types/models/account-staff'

const transformInvitedStaff = ({ id, name, expires_at }: InvitedStaffDto): InvitedStaff => ({
  id,
  name,
  expiresAt: expires_at,
})

const transformManageableAccountDto = ({
  user_id,
  name,
  icon,
}: ManageableAccountDto): ManageableAccount => ({
  userId: user_id,
  name,
  icon,
})

export const transformAccountStaff = ({
  staff_accounts,
  invited_accounts,
  limit,
}: AccountStaffDto): AccountStaffModel => ({
  acceptedStaff: staff_accounts,
  invitedStaff: invited_accounts.map(transformInvitedStaff),
  limit,
})

export const transformManageableAccountsDto = (
  manageableAccounts: Array<ManageableAccountDto>,
): Array<ManageableAccount> => manageableAccounts.map(transformManageableAccountDto)
