'use client'

import { MouseEvent } from 'react'
import { Label } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'
import useConsent from 'hooks/useConsent'
import { NAVIGATION_COOKIES_URL } from 'constants/catalog'
import { toggleOneTrustInfoDisplay } from 'libs/utils/window'

import OtherLink from '../OtherLink'

const Privacy = () => {
  const translate = useTranslate('header.main_navigation.about.privacy')

  const { isCookieConsentVersion } = useConsent()

  const handleCookiesClick = (event: MouseEvent) => {
    event.preventDefault()
    toggleOneTrustInfoDisplay()
  }

  if (!isCookieConsentVersion) return null

  return (
    <>
      <Label styling={Label.Styling.Wide} text={translate('title')} />
      <OtherLink
        url={NAVIGATION_COOKIES_URL}
        title={translate('items.cookies')}
        onClick={handleCookiesClick}
      />
    </>
  )
}

export default Privacy
