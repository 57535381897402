import { Hostname } from 'constants/hostnames'
import { CountryCode } from 'constants/country'
import { PageId } from 'types/page-id'

export const SMALLEST_AD_HEIGHT = 50
export const GOOGLETAG_WAIT_CYCLES_LIMIT = 10
export const GOOGLETAG_WAIT_TIME = 250
export const GOOGLETAG_GENDER_VALUE_KEY = 'bs_a'
export const STICKY_AD_SPACING = 20
export const REFRESH_AD_TIME = 15
export const USER_INACTIVE_TIME = 5
export const DEBOUNCE_TIME = 2000
export const ADS_BIDDER_TIMEOUT = 2000
export const AD_VISIBILITY_RATIO = 0.5
export const IS_AD_BLOCKER_USED_SESSION_KEY = 'isAdBlockerUsed'
export const AD_BLOCKER_VISITOR_KEY = 'adBlockerVisitor'
export const AD_REQUEST_CHECK_URL = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'

export const APSTAG_PUB_ID = 3863

export const ACTIVITY_EVENTS = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart']

export const SHOULD_SHOW_ROKT_SESSION_KEY = 'shouldShowRokt'
export const ROKT_ORDER_PRICE_SESSION_KEY = 'roktOrderPrice'
export const ROKT_ACCOUNT_ID = '3105142925732150316'
export const ROKT_HVF_PRICE_THRESHOLD = 200

export enum RoktDfpCode {
  De = 'de_web_successful_purchase_rokt',
  Fr = 'fr_web_successful_purchase_rokt',
  Uk = 'uk_web_successful_purchase_rokt',
}

export enum Bidder {
  Amazon = 'a9',
  Prebid = 'prebid',
}

export enum BidderState {
  Idle = 'idle',
  Fetched = 'fetched',
  TimedOut = 'timedout',
  NoBids = 'nobids',
  BadSizes = 'badsizes',
}

export enum GoogleEventName {
  SlotOnload = 'slotOnload',
  SlotRequested = 'slotRequested',
  SlotRenderEnded = 'slotRenderEnded',
  ImpressionViewable = 'impressionViewable',
}

export enum AdKind {
  Rtb = 'rtb',
  Van = 'van',
}

export enum AdPlatform {
  Mobile = 'mobile',
  Web = 'web',
}

export enum AdShape {
  Leaderboard = 'leaderboard',
  Rectangle = 'rectangle',
  Skyscraper = 'skyscraper',
  Inbetween = 'inbetween',
}

export enum AdPage {
  Feed = 'feed',
  Catalog = 'catalog',
  Messages = 'messages',
  NewMessage = 'new_message',
  ReplyMessage = 'reply_message',
  Item = 'item',
  FavoriteItems = 'favorite_items',
  Notifications = 'notifications',
  Unknown = 'unknown',
}

export enum RoktEvent {
  PlacementReady = 'PLACEMENT_READY',
}

export enum PrebidPriceGranularityType {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  Auto = 'auto',
  Dense = 'dense',
}

export enum PrebidUserSyncFilterSettingType {
  Image = 'image',
  Iframe = 'iframe',
}

export enum PrebidConsentManagementCmpApiType {
  IAB = 'iab',
  Static = 'static',
}

export const MapCountryCodeToRoktDfpCode: Partial<Record<string, RoktDfpCode>> = {
  [CountryCode.De]: RoktDfpCode.De,
  [CountryCode.Fr]: RoktDfpCode.Fr,
  [CountryCode.Uk]: RoktDfpCode.Uk,
}

export const MapPageIdToAdPage: Partial<Record<PageId, AdPage | undefined>> = {
  catalog: AdPage.Catalog,
  brand: AdPage.Catalog,
  'catalog-by-id': AdPage.Catalog,
  'member-items-favourite-list': AdPage.FavoriteItems,
  root: AdPage.Feed,
  item: AdPage.Item,
  inbox: AdPage.Messages,
  'inbox-message': AdPage.Messages,
  'notification-landings': AdPage.Notifications,
  'member-notifications': AdPage.Notifications,
}

export const MapHostnameToPubstackKey: Partial<Record<Hostname, string>> = {
  [Hostname.At]: '2002dd12-cfdb-4c86-acac-9cdf9486fcaf',
  [Hostname.Be]: 'ffb4f10f-8663-4550-a525-581be2c04b19',
  [Hostname.Cz]: 'f7d00be3-675b-40d6-b04c-ca6a6116c111',
  [Hostname.De]: 'd9b97cad-0390-4322-bbd7-b7bb6bcba9a9',
  [Hostname.OldDe]: 'f9aea0b3-61d6-4409-ae0c-9bf1f5d413b0',
  [Hostname.OldDeBabies]: '23d6f372-1367-4b88-88fe-28077a20088d',
  [Hostname.Es]: 'fa8cbf13-e46c-4af7-b37e-126c04513563',
  [Hostname.Fr]: '8599f048-3b81-470f-8617-c842452d2caf',
  [Hostname.It]: '6990de06-43b4-4b6e-aa67-5169f9feef26',
  [Hostname.Lu]: '20292402-2e23-49f5-bee3-ac87ee790010',
  [Hostname.Lt]: '6ffbb4d6-2fe9-4fa8-a370-70ce9ac60035',
  [Hostname.Nl]: '4987b2a4-90ae-48b2-b3ed-075cb6593164',
  [Hostname.Pl]: '828f9c88-2f78-4f2c-b3b7-d70b26c99137',
  [Hostname.Pt]: 'ffb5949f-6dfa-4488-9e7d-98839a8ee904',
  [Hostname.Uk]: '304e637f-56e5-40f3-8bd5-70dd486db82e',
  [Hostname.Us]: 'fbd1ccbf-c46c-4860-a81f-1c40283de838',
  [Hostname.Hu]: '8a0bc538-e320-44f2-98d7-08288503d011',
  [Hostname.Sk]: '6ec23816-a6ec-4d89-8120-c72a645dad6c',
  [Hostname.Se]: '4486c5b4-9e92-4a49-b660-dd1fec9a9399',
  [Hostname.Ro]: '62ad18e6-64d6-4916-980f-8770bf60083b',
  [Hostname.Dk]: '24ec2372-6988-438b-806c-01a100181c22',
  [Hostname.Fi]: '85d6ce68-2e14-478e-84e6-d98fc2a3be56',
  [Hostname.Gr]: '05d9e8c0-bf21-4cc6-86bc-14b92d967088',
  [Hostname.Hr]: '083a9890-1220-45e1-b6d1-316d7f571074',
  [Hostname.Ie]: 'd0dfb0cd-f5fe-4960-b9a1-61440ed67357',
}

export const MapHostnameToYieldbirdKey: Partial<Record<Hostname, string>> = {
  [Hostname.At]: 'f610af51-4a9b-44e0-b9fe-1096217e469c',
  [Hostname.Be]: 'aaa6f9be-006d-43dd-b59e-b00e52cd58e2',
  [Hostname.Us]: 'd1fa6960-9b98-4bfd-904b-3805d91d9b0a',
  [Hostname.Cz]: '8ef0c6cf-b290-4199-b2c5-68785146dbd0',
  [Hostname.De]: '8f1638ea-c14a-4bce-af0e-5263553a6949',
  [Hostname.Es]: 'd03d395d-3c46-43c0-990f-b10906a5d251',
  [Hostname.Hu]: '0d5c622c-bc49-4dad-b77b-742203544947',
  [Hostname.It]: '87c0352f-1f2c-4e6c-9d45-a5212a80108a',
  [Hostname.Lt]: 'fc1f0225-e0d2-4fac-ba93-31400401d06f',
  [Hostname.Lu]: '11244d35-f89f-4696-86dc-e5d4f08bc0d3',
  [Hostname.Nl]: '5ab50f3c-1ecd-4f82-bc87-190327181ed7',
  [Hostname.Pt]: '71fce85d-01a0-4f37-bd9e-31719c66e978',
  [Hostname.Ro]: '86bb14b2-8652-42aa-b28a-7acd6598bb96',
  [Hostname.Se]: '4ec9b925-8eb7-4858-abe4-09e756fc2f79',
  [Hostname.Sk]: '76347daa-cc20-4525-a291-dbc6561e4148',
  [Hostname.Fr]: '8d9afc26-9acb-4d12-a757-db592bf7580f',
  [Hostname.Pl]: '6b332917-e417-4b19-b60c-caef1e296484',
  [Hostname.Uk]: '181d93cf-bbde-4771-bbcd-6efc605f5183',
}
