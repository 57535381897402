'use client'

import { Text } from '@vinted/web-ui'
import { Suspense } from 'react'

import useTranslate from 'hooks/useTranslate'

const AdInfo = () => {
  const translate = useTranslate('advertisements')

  return (
    <Suspense>
      <div className="ad-info" data-testid="ad-info" suppressHydrationWarning>
        <Text as="h4" type={Text.Type.Caption} theme="muted" text={translate('advertisement')} />
      </div>
    </Suspense>
  )
}

export default AdInfo
