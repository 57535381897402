'use client'

import { Button, Icon } from '@vinted/web-ui'
import { Heart24 } from '@vinted/monochrome-icons'
import { useCallback } from 'react'

import { USER_FAVOURITE_ITEMS_URL } from 'constants/routes'
import useTranslate from 'hooks/useTranslate'
import useUserStats from 'hooks/useUserStats'

import HeaderConversations from './HeaderConversations'
import HeaderNotifications from './HeaderNotifications'

const HeaderUserActions = () => {
  const translate = useTranslate('header')
  const { userStats, fetchUserStats } = useUserStats()

  const { unreadMsgCount: unreadConversationCount = 0, unreadNotificationCount = 0 } =
    userStats ?? {}

  const handleNotificationsFetched = useCallback(() => {
    if (unreadNotificationCount > 0) fetchUserStats()
  }, [unreadNotificationCount, fetchUserStats])

  return (
    <>
      <div className="u-position-relative">
        <HeaderConversations unreadConversationCount={unreadConversationCount} />
      </div>
      <div className="u-position-relative">
        <HeaderNotifications
          // When fetching the notifications, we tell the backend to mark ALL notifications as read.
          // To make sure that the stats reflect that, we refetch the stats *after* we fetch the notifications
          onNotificationsFetched={handleNotificationsFetched}
          unreadNotificationCount={unreadNotificationCount}
        />
      </div>
      <div className="u-position-relative">
        <Button
          aria={{ 'aria-label': translate('a11y.favorited_items') }}
          icon={<Icon name={Heart24} color={Icon.Color.GreyscaleLevel4} />}
          styling={Button.Styling.Flat}
          url={USER_FAVOURITE_ITEMS_URL}
        />
      </div>
    </>
  )
}

export default HeaderUserActions
