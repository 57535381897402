'use client'

import { useMemo, useState } from 'react'

import { AdsPlacementModel } from 'types/models'

import AdsContext from './AdsContext'

type Props = {
  placements: Array<AdsPlacementModel>
  segments: Record<string, string>
  shouldMockAds: boolean
  children: React.ReactNode
}

const AdsProvider = ({ placements, segments, shouldMockAds, children }: Props) => {
  const [hasAdBlockerBeenTracked, setHasAdBlockerBeenTracked] = useState<boolean>(false)
  const [isAdBlockerUsed, setIsAdBlockerUsed] = useState<boolean | null>(null)
  const [adBlockerVisitorId, setAdBlockerVisitorId] = useState<string | null>(null)

  const value = useMemo(
    () => ({
      placements,
      segments,
      shouldMockAds,
      isAdBlockerUsed,
      hasAdBlockerBeenTracked,
      adBlockerVisitorId,
      setIsAdBlockerUsed,
      setHasAdBlockerBeenTracked,
      setAdBlockerVisitorId,
    }),
    [
      placements,
      segments,
      shouldMockAds,
      adBlockerVisitorId,
      isAdBlockerUsed,
      hasAdBlockerBeenTracked,
      setIsAdBlockerUsed,
      setHasAdBlockerBeenTracked,
      setAdBlockerVisitorId,
    ],
  )

  return <AdsContext.Provider value={value}>{children}</AdsContext.Provider>
}

export default AdsProvider
