'use client'

import { ReactNode } from 'react'
import { useRouter } from 'next/router'
import { Spacer } from '@vinted/web-ui'
import classNames from 'classnames'

import AdsProvider from 'containers/AdsProvider'

import Advertisement from 'components/Advertisement'
import AdvertisementBlockCheck from 'components/AdvertisementBlockCheck'
import AppBanner from 'components/AppBanner'
import DomainSelectModal from 'components/DomainSelectModal'
import { GoogleAnalyticsTracker } from 'components/Google/Analytics'
import Header from 'components/Header'
import HeaderNoNavigation from 'components/HeaderNoNavigation'
import InAppMessage from 'libs/common/braze/components/InAppMessage'
import MainFooter from 'components/MainFooter'
import PolicyFooter from 'components/PolicyFooter'
import VacationNotification from 'components/VacationNotification'
import GlobalTwoFaModal from 'components/GlobalTwoFAModal'
import ErrorBoundary from 'components/ErrorBoundary'
import FlashMessage from 'components/FlashMessage'
import {
  INBOX_PAGES,
  LAYOUT_BREAKOUT_PAGES,
  NON_WHITE_BACKGROUND_PAGES,
  NO_SPACER_BEFORE_MAIN_FOOTER_PAGES,
  POLICY_FOOTER_PAGES,
} from 'constants/layouts/full'
import { AdShape } from 'constants/ads'
import { CatalogDto, CountryLinkDto, LanguageDto } from 'types/dtos'
import { SearchBarSearchType } from 'constants/search'
import useIsInPage from 'hooks/useIsInPage'
import useSession from 'hooks/useSession'
import usePageId from 'hooks/usePageId'
import useFeatureSwitch from 'hooks/useFeatureSwitch'
import useSystemConfiguration from 'hooks/useSystemConfiguration'
import { SavedSearchesProvider } from 'components/Header/SavedSearchesProvider'

import { AdsPlacementModel } from 'types/models'

import AdScripts from '../../components/AdScripts'
import { withLayout } from '../../libs/server-utils/hoc'

export type Props = {
  languages: Array<LanguageDto>
  catalogTree: Array<CatalogDto>
  hasNoNavigation: boolean
  domainLinks: Array<CountryLinkDto> | null
  children: ReactNode
  adsPlacements: Array<AdsPlacementModel>
  adsSegments: Record<string, string>
  shouldMockAds: boolean
}

const resolveHeaderSearchType = (pathname: string): SearchBarSearchType => {
  const FIRST_VALUE_AFTER_SLASH_INDEX = 1
  const firstValueAfterSlash = pathname.split('/')[FIRST_VALUE_AFTER_SLASH_INDEX]

  if (firstValueAfterSlash === 'member') return SearchBarSearchType.User
  if (firstValueAfterSlash === 'help') return SearchBarSearchType.Faq

  return SearchBarSearchType.Item
}

function useWhiteBackground() {
  const pageId = usePageId()
  const isUpdatedFeedbackPageEnabled = useFeatureSwitch('updated_feedback_page')

  if (!pageId) return true
  if (pageId === 'feedback') return isUpdatedFeedbackPageEnabled

  return !NON_WHITE_BACKGROUND_PAGES.includes(pageId)
}

export const FullPageLayout = ({
  languages,
  catalogTree,
  hasNoNavigation,
  domainLinks,
  children,
  adsPlacements,
  adsSegments,
  shouldMockAds,
}: Props) => {
  const systemConfiguration = useSystemConfiguration()
  const isWebGa4AnalyticsEnabled = useFeatureSwitch('web_ga4_analytics')
  const { isContentOnlyView: isContentOnly } = useSession()
  const router = useRouter()
  const { isWebview } = useSession()
  const isInPolicyFooterPage = useIsInPage(POLICY_FOOTER_PAGES)
  const isInInboxPage = useIsInPage(INBOX_PAGES)
  const isInNoSpacerBeforeMainFooterPage = useIsInPage(NO_SPACER_BEFORE_MAIN_FOOTER_PAGES)
  const shouldBreakoutLayout = useIsInPage(LAYOUT_BREAKOUT_PAGES)
  const isWhiteBackground = useWhiteBackground()

  if (!systemConfiguration) return null

  const renderHeader = () => {
    if (isContentOnly) return null

    if (hasNoNavigation) {
      return <HeaderNoNavigation />
    }

    return (
      <Header
        languages={languages}
        catalogTree={catalogTree}
        searchType={resolveHeaderSearchType(router.pathname)}
        impressumUrl={systemConfiguration.urls.impressum}
        isBusinessAccountLinksVisible={systemConfiguration.businessAccountInformationalLinksVisible}
        isOurPlatformVisible={!!systemConfiguration.urls.ourPlatform}
      />
    )
  }

  const renderFooter = () => {
    if (isContentOnly) return null

    if (!isInPolicyFooterPage && !hasNoNavigation) {
      return (
        <>
          {!isInNoSpacerBeforeMainFooterPage && <Spacer size={Spacer.Size.X5Large} />}
          <div className="u-ui-margin-top-auto">
            <MainFooter
              isBusinessInfoLinksEnabled={
                systemConfiguration.businessAccountInformationalLinksVisible
              }
              isDigitalLawEnabled={!!systemConfiguration.urls.ourPlatform}
              onlineComplaintUrl={systemConfiguration.urls.onlineComplaints}
            />
          </div>
        </>
      )
    }

    return (
      <PolicyFooter
        isDigitalLawEnabled={!!systemConfiguration.urls.ourPlatform}
        isBusinessInfoLinksEnabled={systemConfiguration.businessAccountInformationalLinksVisible}
        onlineComplaintUrl={systemConfiguration.urls.onlineComplaints}
        isStatic={isInInboxPage}
        noDivider={isInInboxPage}
      />
    )
  }

  return (
    <AdsProvider placements={adsPlacements} segments={adsSegments} shouldMockAds={shouldMockAds}>
      {/*
        It is important to keep `AdScripts` above
        `Advertisement` component to initialize various
        third party scripts like googletag or etc.
        that are necessary for `Advertisement` component
        to actually render an advertisement.
      */}
      <AdScripts />

      <div
        className={classNames('fullpage-layout is-wide', {
          'u-background-white': isWhiteBackground,
        })}
      >
        <InAppMessage />
        {domainLinks && <DomainSelectModal domainLinks={domainLinks} />}

        <SavedSearchesProvider>
          {renderHeader()}

          <main className="site">
            <div className="site-wrapper">
              <ErrorBoundary FallbackComponent={ErrorBoundary.LayoutError}>
                <Advertisement shape={AdShape.Leaderboard} id="ad-leaderboard" />

                <div className="site-content">
                  <div className={classNames({ container: !shouldBreakoutLayout })}>
                    <div className={classNames({ container: shouldBreakoutLayout })}>
                      {!isWebview && <VacationNotification />}
                    </div>

                    <div
                      className={classNames('row u-position-relative', {
                        'u-margin-horizontal-none': shouldBreakoutLayout,
                      })}
                    >
                      <div
                        id="content"
                        className={classNames('grid12', {
                          'u-padding-horizontal-none': shouldBreakoutLayout,
                        })}
                      >
                        <div className="body-content">
                          <FlashMessage />
                          {children}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <AppBanner />
                <AdvertisementBlockCheck />
                {isWebGa4AnalyticsEnabled && <GoogleAnalyticsTracker />}
              </ErrorBoundary>
            </div>
          </main>
        </SavedSearchesProvider>

        {renderFooter()}
        <GlobalTwoFaModal />
      </div>
    </AdsProvider>
  )
}

export default withLayout<Props>(FullPageLayout)
