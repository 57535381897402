import { useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'

import useTracking from 'hooks/useTracking'
import useFeatureSwitch from 'hooks/useFeatureSwitch'
import useSystemConfiguration from 'hooks/useSystemConfiguration'

import { receivedAdEvent, viewAdEvent } from 'libs/common/event-tracker/events'
import { AdKind, AdPlatform } from 'constants/ads'
import { AdsPlacementModel } from 'types/models'

import { getAdPlacementId } from '../utils'

type Props = {
  isAdRendered: boolean
  placementConfig: AdsPlacementModel
}

const useAdEventTracking = ({ isAdRendered, placementConfig }: Props) => {
  const viewableImpressionTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const isViewableImpressionTracked = useRef(false)
  const isImpressionTrackingEnabled = useFeatureSwitch('web_ads_impression_tracking')
  const { userCountry: countryCode } = useSystemConfiguration() || {}

  const { track } = useTracking()

  const placementId = getAdPlacementId(placementConfig)

  const { ref: viewableImpressionRef } = useInView({
    threshold: [0, 0.5],
    root: null,
    rootMargin: '0px',
    onChange: (_, entry) => {
      // intersectionRatio of 0.5 represents 50% of the ad being visible
      // and viewable impression (viewAdEvent) has to be tracked after
      // 50% of the ad being visible for at least 1 second

      if (viewableImpressionTimeoutRef.current) {
        clearTimeout(viewableImpressionTimeoutRef.current)
        viewableImpressionTimeoutRef.current = null
      }

      if (
        isViewableImpressionTracked.current ||
        !countryCode ||
        !entry.isIntersecting ||
        entry.intersectionRatio < 0.5
      )
        return

      viewableImpressionTimeoutRef.current = setTimeout(() => {
        isViewableImpressionTracked.current = true

        track(
          viewAdEvent({
            placementId,
            isMobileWeb: placementConfig.platform === AdPlatform.Mobile,
            countryCode,
            creativeId:
              placementConfig.kind === AdKind.Van ? placementConfig.creative.id : undefined,
            campaignId: placementConfig.kind === AdKind.Van ? placementConfig.id : undefined,
          }),
        )
      }, 1000)
    },
    skip: !isImpressionTrackingEnabled || !isAdRendered,
  })

  const { ref: impressionRef } = useInView({
    threshold: [0.0, 0.001],
    root: null,
    rootMargin: '0px',
    onChange: (_, entry) => {
      // intersectionRatio of 0.001 represents 1px of the ad being visible
      // and to match the logic of mobile platforms impression (receivedAdEvent)
      // should be tracked only when user is exposed to at least 1px of the ad

      if (!countryCode || !entry.isIntersecting || entry.intersectionRatio < 0.001) return

      track(
        receivedAdEvent({
          placementId,
          isMobileWeb: placementConfig.platform === AdPlatform.Mobile,
          countryCode,
          creativeId: placementConfig.kind === AdKind.Van ? placementConfig.creative.id : undefined,
          campaignId: placementConfig.kind === AdKind.Van ? placementConfig.id : undefined,
        }),
      )
    },
    skip: !isImpressionTrackingEnabled || !isAdRendered,
    triggerOnce: true,
  })

  useEffect(() => {
    if (isAdRendered) return

    if (viewableImpressionTimeoutRef.current) {
      clearTimeout(viewableImpressionTimeoutRef.current)
      viewableImpressionTimeoutRef.current = null
    }

    isViewableImpressionTracked.current = false
  }, [isAdRendered])

  return {
    impressionRef,
    viewableImpressionRef,
  }
}

export default useAdEventTracking
