import { Image } from '@vinted/web-ui'

import { InboxNotificationType } from 'constants/inbox-notification'
import { UserNotificationDto } from 'types/dtos'
import { GenericInboxNotificationModel } from 'types/models'

export const transformUserNotificationDto = (
  dto: UserNotificationDto,
): GenericInboxNotificationModel => ({
  id: String(dto.id),
  body: dto.body,
  link: dto.link,
  photoUrl: dto.small_photo_url,
  photoStyle: dto.photo_type === 'user' ? Image.Styling.Circle : Image.Styling.Rounded,
  time: new Date(dto.updated_at).getTime(),
  type: InboxNotificationType.Vinted,
  isViewed: dto.is_read,
  note: dto.note,
  isControl: false,
})
