'use client'

import { MouseEvent } from 'react'
import { Button } from '@vinted/web-ui'

import classNames from 'classnames'

import HeaderLogo from 'components/HeaderLogo'
import LanguageSelector from 'components/LanguageSelector'
import useTranslate from 'hooks/useTranslate'

import { logoutUser } from 'data/api/authentication/requests'
import { navigateToPage } from 'libs/utils/window'
import { ROOT_URL } from 'constants/routes'
import useCookie from 'libs/common/cookie-manager/hooks/useCookie'
import useSession from 'hooks/useSession/useSession'

import { useEmailCodeTest } from './hooks/useEmailCodeTest'

type Props = {
  standaloneLanguageSelector?: boolean
}

const HeaderNoNavigation = ({ standaloneLanguageSelector }: Props) => {
  const { user } = useSession()
  const translate = useTranslate()
  const {
    trackLogoutEvent,
    isLoadingPrompts,
    trackEmailCodeSkipEvent,
    handleDismissVerificationPrompt,
  } = useEmailCodeTest()
  const cookies = useCookie()

  const handleLogoutClick = async () => {
    const response = await logoutUser({ cookies })

    if (response) {
      trackLogoutEvent()
      navigateToPage(ROOT_URL)
    }
  }

  const handleDismissPrompt = async () => {
    await handleDismissVerificationPrompt()

    navigateToPage(ROOT_URL)
  }

  const handleLogoClick = (event: MouseEvent) => {
    event.preventDefault()

    trackEmailCodeSkipEvent()
    handleDismissPrompt()

    return false
  }

  const renderLogo = () => {
    return <HeaderLogo onClick={handleLogoClick} />
  }

  const renderLanguageSelector = () => {
    return (
      <div
        className={classNames({
          'u-position-relative': !user,
          'u-margin-left-auto': !user,
        })}
      >
        <LanguageSelector isStandalone />
      </div>
    )
  }

  const renderLogoutButton = () => {
    if (!user) return null

    return (
      <div className="u-flexbox u-margin-left-auto u-align-items-center u-position-relative u-z-index-notification">
        <Button
          theme="muted"
          styling={Button.Styling.Flat}
          size={Button.Size.Medium}
          onClick={handleLogoutClick}
          disabled={isLoadingPrompts}
        >
          {translate('header.actions.log_out')}
        </Button>
      </div>
    )
  }

  return (
    <div className="l-header">
      <header className="l-header__main">
        <div className="container u-flexbox u-align-items-center u-fill-height">
          <div className="u-flexbox u-align-items-center u-fill-width">
            {renderLogo()}
            {renderLogoutButton()}
            {standaloneLanguageSelector && renderLanguageSelector()}
          </div>
        </div>
      </header>
    </div>
  )
}

export default HeaderNoNavigation
