'use client'

import { useEffect } from 'react'

import useFeatureSwitch from 'hooks/useFeatureSwitch'
import useIsConsentGroupEnabled from 'hooks/useIsConsentGroupEnabled'

import { ConsentGroup } from 'constants/consent'

import Google from './setups/Google'
import Pubstack from './setups/Pubstack'
import Amazon from './setups/Amazon'
import Prebid from './setups/Prebid'
import Yieldbird from './setups/Yieldbird'
import Confiant from './setups/Confiant'
import Rokt from './setups/Rokt'

const AdScripts = () => {
  const isYieldBirdScriptEnabled = useFeatureSwitch('web_ads_yieldbird_script')
  const isRoktScriptEnabled = useFeatureSwitch('web_ads_rokt_script')
  const shouldLoadScriptsAfterConsent = useFeatureSwitch('web_ads_load_after_consent')

  useEffect(() => {
    window.adPlacements = {}
  }, [])

  const isConsentGroupEnabled = useIsConsentGroupEnabled(ConsentGroup.Targeting)

  if (!isConsentGroupEnabled && shouldLoadScriptsAfterConsent) return null

  return (
    <>
      <Google />
      <Pubstack />
      <Amazon />
      <Prebid />
      {isYieldBirdScriptEnabled && <Yieldbird />}
      {isRoktScriptEnabled && <Rokt />}
      <Confiant />
    </>
  )
}

export default AdScripts
