'use client'

import { ReactNode, useEffect, useState } from 'react'
import { Card, flip, Image, offset, shift, Spacing, Tooltip, useFloating } from '@vinted/web-ui'
import classNames from 'classnames'

import { getSessionStorageItem, removeSessionStorageItem } from 'libs/utils/sessionStorage'
import OutsideClick from 'components/OutsideClick'
import ScrollableArea from 'components/ScrollableArea'
import { ACCOUNT_SWITCH_TOOLTIP_SESSION_STORAGE_KEY } from 'pages/AcceptBusinessInvitation/constatnts'
import useTranslate from 'hooks/useTranslate'
import ConditionalWrapper from 'components/ConditionalWrapper'

const TOOLTIP_DISPLAY_TIME = 8000

type Props = {
  userName: string
  userPhoto: string
  children: ReactNode
}
const UserMenuDropdown = ({ userName, userPhoto, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const accountTooltipStorageValue = getSessionStorageItem(
    ACCOUNT_SWITCH_TOOLTIP_SESSION_STORAGE_KEY,
  )
  const [isSwitchAccountTooltipVisible, setIsSwitchAccountTooltipVisible] = useState(
    !!accountTooltipStorageValue,
  )
  const translate = useTranslate('header.user_menu.account')

  useEffect(() => {
    if (!isSwitchAccountTooltipVisible) return undefined

    const timer = setTimeout(() => {
      setIsSwitchAccountTooltipVisible(false)
      removeSessionStorageItem(ACCOUNT_SWITCH_TOOLTIP_SESSION_STORAGE_KEY)
    }, TOOLTIP_DISPLAY_TIME)

    return () => clearTimeout(timer)
  }, [isSwitchAccountTooltipVisible])

  const handleOutsideClick = () => setIsOpen(false)

  const handleMenuButtonClick = () => {
    setIsOpen(prevIsOpen => !prevIsOpen)
    setIsSwitchAccountTooltipVisible(false)
    removeSessionStorageItem(ACCOUNT_SWITCH_TOOLTIP_SESSION_STORAGE_KEY)
  }

  const { floaterRef, triggerRef, floaterStyle } = useFloating({
    middleware: [offset(Spacing.Medium), flip({ mainAxis: false }), shift()],
    placement: 'bottom-end',
    shouldAutoUpdate: true,
    isFloaterVisible: isOpen,
  })

  const renderUserMenu = () => (
    <button
      type="button"
      id="user-menu-button"
      ref={triggerRef}
      onClick={handleMenuButtonClick}
      className={classNames({ open: isOpen })}
    >
      <figure className="header-avatar">
        <Image src={userPhoto} alt={userName} styling={Image.Styling.Circle} />
      </figure>
    </button>
  )

  const renderUserMenuWithTooltip = () => (
    <Tooltip
      content={translate('switch_to_business_tooltip')}
      placement={Tooltip.Placement.BottomRight}
      show={isSwitchAccountTooltipVisible}
    >
      {renderUserMenu()}
    </Tooltip>
  )

  return (
    <OutsideClick onOutsideClick={handleOutsideClick}>
      <ConditionalWrapper
        condition={isSwitchAccountTooltipVisible}
        wrapper={renderUserMenuWithTooltip}
      >
        {renderUserMenu()}
      </ConditionalWrapper>
      {isOpen && (
        <div ref={floaterRef} style={floaterStyle}>
          <Card styling={Card.Styling.Elevated}>
            <ScrollableArea>
              <div className="user-menu-groups">{children}</div>
            </ScrollableArea>
          </Card>
        </div>
      )}
    </OutsideClick>
  )
}

export default UserMenuDropdown
