'use client'

import { Suspense, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { find, noop } from 'lodash'
import classNames from 'classnames'

import { AdShape, AdPage } from 'constants/ads'
import { ConsentGroup } from 'constants/consent'

import useAbTest from 'hooks/useAbTest'
import useFeatureSwitch from 'hooks/useFeatureSwitch'
import useIsConsentGroupEnabled from 'hooks/useIsConsentGroupEnabled'

import { AdsPlacementModel } from 'types/models'
import AdManager from 'libs/common/ad-manager'
import AdsContext from 'containers/AdsProvider/AdsContext'

import AdContent from './AdContent'
import AdPlaceholder from './AdPlaceholder'
import AdErrorBoundary from './AdErrorBoundary'
import useStickyOptions from './useStickyOptions'
import AdMock from './AdMock'
import { getAdPlacementId } from './utils'

type Props = {
  id?: string
  shape: AdShape
  mediation?: string | null
  config?: AdsPlacementModel
  isManuallyRendered?: boolean
  isManuallyRefreshed?: boolean
  isSidebarAd?: boolean
  onAdRender?: (isAdVisible: boolean) => void
}

function usePlaceholderRemovalTest(shape?: AdShape) {
  let initialShouldRender = true
  const isConsentGroupEnabled = useIsConsentGroupEnabled(ConsentGroup.Targeting)
  const isPlaceholderRemovalTestEnabled = useFeatureSwitch('web_placeholder_removal_test')
  const isLeaderboard = useMemo(() => shape === AdShape.Leaderboard, [shape])

  // Expose event should be tracked only if user is about to see a leaderboard ad
  // and when the consent for targeting purposes is not (yet or at all) given,
  // and when the placeholder removal test feature switch is enabled
  const shouldTrackExpose =
    !isConsentGroupEnabled && isLeaderboard && isPlaceholderRemovalTestEnabled

  const placeholderRemovalTest = useAbTest({
    abTestName: 'web_placeholder_removal',
    shouldTrackExpose,
  })

  if (isLeaderboard) {
    if (isConsentGroupEnabled) {
      initialShouldRender = true
    } else if (isPlaceholderRemovalTestEnabled) {
      initialShouldRender = placeholderRemovalTest?.variant === 'off'
    }
  } else {
    initialShouldRender = true
  }

  const [shouldRender, setShouldRender] = useState<boolean>(initialShouldRender)

  useEffect(() => {
    if (!isLeaderboard) return

    // When the test has variant "b", the default behavior is to not render the ad after consent was given.
    // Meaning, that ad (and the placeholder) should be presented only after the full page reload.
    // In case of "off" variant - it will be rendered initially to begin with, so no need for update either.
    if (isPlaceholderRemovalTestEnabled && placeholderRemovalTest?.variant !== 'a') return

    // However, in variant 'a', the default behavior is to render the ad right after consent was given.
    // Hence, this update.
    setShouldRender(isConsentGroupEnabled)
  }, [
    isConsentGroupEnabled,
    isPlaceholderRemovalTestEnabled,
    placeholderRemovalTest,
    isLeaderboard,
  ])

  return shouldRender
}

const Advertisement = ({
  id: propsId,
  shape,
  mediation = null,
  config,
  isSidebarAd = false,
  isManuallyRendered = false,
  isManuallyRefreshed = false,
  onAdRender = noop,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isAdRendered, setIsAdRendered] = useState<boolean>(false)

  const { shouldMockAds, placements } = useContext(AdsContext)
  const placement = find(placements, { shape, mediation })

  // TODO: Rework this when we have real Van ads
  // For now it will be used only under the condition
  // that web_ads_van_placement feature switch is enabled
  const shouldShowVanAd = useFeatureSwitch('web_ads_van_placement')
  const vanPlacement = find(placements, { shape, mediation: 'van' })

  const placementConfig = useMemo(
    () => config || (shouldShowVanAd && vanPlacement) || placement,
    [config, placement, vanPlacement, shouldShowVanAd],
  )

  const shouldRender = usePlaceholderRemovalTest(placementConfig?.shape)

  const id = useMemo(() => propsId || AdManager.generatePlacementId(), [propsId])

  const stickyOptions = useStickyOptions({
    isSticky: placementConfig?.options.isSticky,
  })

  const handleAdRender = useCallback(
    (isAdVisible: boolean) => {
      onAdRender(isAdVisible)
      setIsAdRendered(isAdVisible)
    },
    [onAdRender],
  )

  if (!placementConfig || !shouldRender) return null

  return (
    <Suspense>
      <AdErrorBoundary
        pageName={placementConfig.page || AdPage.Unknown}
        placementId={getAdPlacementId(placementConfig)}
      >
        {shouldMockAds ? (
          <AdMock shape={shape} isSidebarAd={isSidebarAd} stickyOptions={stickyOptions} />
        ) : (
          <div
            className={classNames(
              'ad-container',
              `ad-container--${shape}`,
              !!stickyOptions && 'ad-sticky',
              isAdRendered && 'ad-container--rendered',
              isSidebarAd && 'ad-sidebar',
            )}
            data-testid="advertisement"
            style={{ top: stickyOptions?.offset }}
            ref={ref}
            suppressHydrationWarning
          >
            <AdPlaceholder shape={shape} platform={placementConfig.platform} />
            <AdContent
              id={id}
              placementConfig={placementConfig}
              isManuallyRefreshed={isManuallyRefreshed}
              isManuallyRendered={isManuallyRendered}
              onAdRender={handleAdRender}
              isAdRendered={isAdRendered}
            />
          </div>
        )}
      </AdErrorBoundary>
    </Suspense>
  )
}

export default Advertisement
