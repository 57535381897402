'use client'

import { useCallback, useState } from 'react'

import { NotificationCloseType } from 'components/Notification'
import useTracking from 'hooks/useTracking'
import useBrazeInAppMessage from 'libs/common/braze/hooks/useBrazeInAppMessage'

import {
  InAppMessageDismissMethod,
  InAppModalMessageModel,
  InAppNotificationMessageModel,
} from 'libs/common/braze/types/models/in-app-message'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { clickEvent } from 'libs/common/event-tracker/events'
import { Screen } from 'constants/tracking/screens'

import {
  brazeLogInAppMessageButtonClick,
  brazeLogInAppMessageClick,
  brazeLogInAppMessageImpression,
} from 'libs/common/braze/utils/event-loggers'

import NotificationInAppMessage from './NotificationInAppMessage'
import ModalInAppMessage from './ModalInAppMessage'

interface TargetDetails {
  campaign_name?: string
  campaign_message_name?: string
  canvas_name?: string
  canvas_variant_name?: string
  dismiss_method?: string
  dismiss_duration?: number
  url?: string
}

const InAppMessage = () => {
  const [isModalOpen, setIsModalOpen] = useState(true)

  const { track } = useTracking()
  const { notificationInAppMessage, modalInAppMessage } = useBrazeInAppMessage()

  const anyInAppMessage = notificationInAppMessage ?? modalInAppMessage

  const trackDismissal = (
    inAppMessage: InAppModalMessageModel | InAppNotificationMessageModel | null,
    dismissMethod: InAppMessageDismissMethod,
  ) => {
    if (!inAppMessage) return

    const targetDetails: TargetDetails = {
      campaign_name: inAppMessage.campaignName,
      campaign_message_name: inAppMessage.campaignMessageName,
      canvas_name: inAppMessage.canvasName,
      canvas_variant_name: inAppMessage.canvasVariantName,
      dismiss_method: dismissMethod,
    }

    if (dismissMethod === InAppMessageDismissMethod.AutoDismiss && inAppMessage.duration) {
      const formattedDuration = inAppMessage.duration / 1000
      targetDetails.dismiss_duration = formattedDuration
    }

    track(
      clickEvent({
        target: ClickableElement.CrmInAppMessageDismiss,
        screen: Screen.CrmInAppMessage,
        targetDetails: JSON.stringify(targetDetails),
      }),
    )
  }

  const closeModal = (dismissMethod: InAppMessageDismissMethod) => {
    setIsModalOpen(false)
    trackDismissal(modalInAppMessage, dismissMethod)
  }

  const handleCloseButtonClick = () => {
    closeModal(InAppMessageDismissMethod.CloseButton)
  }

  const handleBackgroundClick = () => {
    closeModal(InAppMessageDismissMethod.BackgroundClick)
  }

  const handlePrimaryButtonClick = () => {
    if (!modalInAppMessage) return

    if (!modalInAppMessage.primaryButtonUrl) closeModal(InAppMessageDismissMethod.NoUrlButton)

    brazeLogInAppMessageButtonClick(modalInAppMessage, 0)
  }

  const handleSecondaryButtonClick = () => {
    if (!modalInAppMessage) return

    if (!modalInAppMessage.secondaryButtonUrl) closeModal(InAppMessageDismissMethod.NoUrlButton)

    brazeLogInAppMessageButtonClick(modalInAppMessage, 1)
  }

  const handleModalLinkClick = (url: string) => {
    if (!modalInAppMessage) return

    const targetDetails: TargetDetails = {
      campaign_name: modalInAppMessage.campaignName,
      campaign_message_name: modalInAppMessage.campaignMessageName,
      canvas_name: modalInAppMessage.canvasName,
      canvas_variant_name: modalInAppMessage.canvasVariantName,
      url,
    }

    track(
      clickEvent({
        target: ClickableElement.CrmMessageLink,
        screen: Screen.CrmInAppMessage,
        targetDetails: JSON.stringify(targetDetails),
      }),
    )
  }

  const handleNotificationClose = (closeType: NotificationCloseType | undefined) => {
    if (!notificationInAppMessage) {
      return
    }

    switch (closeType) {
      case NotificationCloseType.Auto:
        trackDismissal(notificationInAppMessage, InAppMessageDismissMethod.AutoDismiss)
        break
      case NotificationCloseType.CloseButtonClick:
        trackDismissal(notificationInAppMessage, InAppMessageDismissMethod.CloseButton)
        brazeLogInAppMessageClick(notificationInAppMessage)
        break
      default:
        break
    }
  }

  const handleNotificationLinkClick = () => {
    if (!notificationInAppMessage?.url) return

    brazeLogInAppMessageClick(notificationInAppMessage)
  }

  const handleInAppMessageEnter = useCallback(() => {
    if (!anyInAppMessage) return

    brazeLogInAppMessageImpression(anyInAppMessage)
  }, [anyInAppMessage])

  if (notificationInAppMessage) {
    return (
      <NotificationInAppMessage
        onEnter={handleInAppMessageEnter}
        inAppMessage={notificationInAppMessage}
        onClose={handleNotificationClose}
        onLinkClick={handleNotificationLinkClick}
      />
    )
  }

  if (modalInAppMessage) {
    return (
      <ModalInAppMessage
        isOpen={isModalOpen}
        inAppMessage={modalInAppMessage}
        onCloseButtonClick={handleCloseButtonClick}
        onBackgroundClick={handleBackgroundClick}
        onLinkClick={handleModalLinkClick}
        onEnter={handleInAppMessageEnter}
        onPrimaryButtonClick={handlePrimaryButtonClick}
        onSecondaryButtonClick={handleSecondaryButtonClick}
      />
    )
  }

  return null
}

export default InAppMessage
