'use client'

import { Badge, Spacer } from '@vinted/web-ui'

import { getUserThumbnail } from 'data/utils/user'
import { logoutUser } from 'data/api/authentication/requests'
import {
  DONATIONS_SETTINGS_URL,
  GO_TO_WALLET_URL,
  MEMBER_PROFILE_URL,
  PROFILE_SETTINGS_URL,
  REFERRALS_URL,
  ROOT_URL,
  USER_PERSONALIZATION_SIZES_URL,
  WALLET_INVOICES_URL,
  MY_ORDERS_URL,
} from 'constants/routes'
import { OrderType } from 'constants/my-orders'
import { EMPTY_USER_IMAGE_NAME } from 'constants/images'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import useShouldShowDarkModeAdoption from 'hooks/useShouldShowDarkModeBanner'
import useFeatureSwitch from 'hooks/useFeatureSwitch'
import useAsset from 'hooks/useAsset'
import { clickEvent } from 'libs/common/event-tracker/events'
import { navigateToPage } from 'libs/utils/window'
import useCookie from 'libs/common/cookie-manager/hooks/useCookie'
import useSession from 'hooks/useSession'

import { Screen } from 'constants/tracking/screens'

import CurrentWalletAmount from './CurrentWalletAmount'
import UserMenuDropdown from './UserMenuDropdown'
import UserMenuGroup from './UserMenuGroup'
import AccountSwitch from './AccountSwitch'

const UserMenu = () => {
  const { user } = useSession()
  const translate = useTranslate()
  const { track } = useTracking()
  const cookies = useCookie()
  const asset = useAsset('assets/no-photo')

  const isPaymentsEnabled = useFeatureSwitch('payments')
  const shouldShowMyOrdersNewBadge = useFeatureSwitch('my_orders_new_badge_web')
  const showFundraiser = !user?.business
  const isReferralsEnabled = useFeatureSwitch('referrals')
  const shouldShowDarkModeAdoption = useShouldShowDarkModeAdoption()
  const isImpersonatingBusiness = user?.impersonating_business

  if (!user) return null

  const userPhoto = getUserThumbnail(user.photo) || asset(EMPTY_USER_IMAGE_NAME)

  const handleLogoutClick = async () => {
    const response = await logoutUser({ cookies })

    if (response) navigateToPage(ROOT_URL)
  }

  const handleFundraiserClick = () =>
    track(
      clickEvent({
        target: ClickableElement.EnterDonationFromSettings,
        screen: Screen.UserMenu,
      }),
    )

  const handleProfileClick = () =>
    track(
      clickEvent({
        target: ClickableElement.Profile,
        screen: Screen.UserMenu,
      }),
    )

  const handleSettingsClick = () => {
    track(
      clickEvent({
        target: ClickableElement.Settings,
        screen: Screen.UserMenu,
      }),
    )
  }

  const handleCustomizeClick = () =>
    track(
      clickEvent({
        target: ClickableElement.Customize,
        screen: Screen.UserMenu,
      }),
    )

  const handleBalanceClick = () =>
    track(
      clickEvent({
        target: ClickableElement.Balance,
        screen: Screen.UserMenu,
      }),
    )

  const renderWalletAction = () => (
    <UserMenuGroup.Action href={GO_TO_WALLET_URL} onClick={handleBalanceClick}>
      <span className="u-flexbox u-justify-content-between u-align-items-center">
        {translate('header.user_menu.account.items.wallet.title')}
        <span className="u-ui-padding-left-regular">
          <CurrentWalletAmount />
        </span>
      </span>
    </UserMenuGroup.Action>
  )

  const renderMyOrdersAction = () => (
    <UserMenuGroup.Action
      href={MY_ORDERS_URL({
        type: OrderType.Sold,
      })}
    >
      {translate('header.user_menu.account.items.my_orders.title')}
      <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
      {shouldShowMyOrdersNewBadge && (
        <Badge theme="primary" content={translate('common.new_badge')} />
      )}
    </UserMenuGroup.Action>
  )

  const renderFundraiserAction = () => (
    <UserMenuGroup.Action href={DONATIONS_SETTINGS_URL} onClick={handleFundraiserClick}>
      {translate('header.user_menu.account.items.donations.title')}
    </UserMenuGroup.Action>
  )

  const renderLogoutAction = () => (
    <UserMenuGroup.Action onClick={handleLogoutClick}>
      <span className="u-color-red">
        {translate('header.user_menu.account.items.logout.title')}
      </span>
    </UserMenuGroup.Action>
  )

  return (
    <UserMenuDropdown userName={user.login} userPhoto={userPhoto}>
      <UserMenuGroup>
        <UserMenuGroup.Item>
          <UserMenuGroup.Action href={MEMBER_PROFILE_URL(user.id)} onClick={handleProfileClick}>
            {translate('header.user_menu.account.items.profile.title')}
          </UserMenuGroup.Action>
          {!isImpersonatingBusiness && (
            <UserMenuGroup.Action href={PROFILE_SETTINGS_URL} onClick={handleSettingsClick}>
              {translate('header.user_menu.account.items.settings.title')}
              {shouldShowDarkModeAdoption && (
                <>
                  <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
                  <Badge theme="primary" content={translate('common.new_badge')} />
                </>
              )}
            </UserMenuGroup.Action>
          )}
          {!isImpersonatingBusiness && (
            <UserMenuGroup.Action
              href={USER_PERSONALIZATION_SIZES_URL}
              onClick={handleCustomizeClick}
            >
              {translate('header.user_menu.account.items.personalization.title')}
            </UserMenuGroup.Action>
          )}
          {isPaymentsEnabled && !isImpersonatingBusiness && renderWalletAction()}
          {renderMyOrdersAction()}
          {showFundraiser && !isImpersonatingBusiness && renderFundraiserAction()}
          {!isPaymentsEnabled && !isImpersonatingBusiness && (
            <UserMenuGroup.Action href={WALLET_INVOICES_URL}>
              {translate('header.user_menu.account.items.invoices.title')}
            </UserMenuGroup.Action>
          )}
          {isReferralsEnabled && !isImpersonatingBusiness && (
            <UserMenuGroup.Action href={REFERRALS_URL}>
              {translate('header.user_menu.account.items.referrals.title')}
            </UserMenuGroup.Action>
          )}
          <AccountSwitch />
          {renderLogoutAction()}
        </UserMenuGroup.Item>
      </UserMenuGroup>
    </UserMenuDropdown>
  )
}

export default UserMenu
