'use client'

import { createContext } from 'react'

import {
  CreateSavedSearchArgs,
  GetSavedSearchArgs,
  SavedSearchApiParams,
  UpdateSavedSearchArgs,
} from 'types/api'
import { SavedSearchDto } from 'types/dtos'

export type SavedSearchesContextType = {
  searches: Array<SavedSearchDto>
  currentSearch: SavedSearchDto | undefined
  currentSearchId: number | undefined
  actions: {
    createSearch: (arg: CreateSavedSearchArgs) => Promise<void>
    fetchSearch: (arg: GetSavedSearchArgs) => Promise<SavedSearchDto | undefined>
    toggleCurrentSearchSubscription: (arg: SavedSearchApiParams) => Promise<void>
    fetchSearches: () => Promise<void>
    toggleSearchSubscription: (searchId: number) => Promise<void>
    updateSearch: (arg: UpdateSavedSearchArgs) => Promise<void>
  }
}

const noop = () => Promise.resolve()

export const savedSearchesContextDefault: SavedSearchesContextType = {
  searches: [],
  currentSearch: undefined,
  currentSearchId: undefined,
  actions: {
    fetchSearches: noop,
    createSearch: noop,
    fetchSearch: () => Promise.resolve(undefined),
    toggleCurrentSearchSubscription: noop,
    toggleSearchSubscription: noop,
    updateSearch: noop,
  },
}

const SavedSearchesContext = createContext<SavedSearchesContextType>(savedSearchesContextDefault)

export default SavedSearchesContext
