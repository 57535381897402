import { IS_DARK_MODE_BANNER_CLOSED_LOCAL_STORAGE_KEY } from 'constants/dark-mode'
import { getLocalStorageItem } from 'libs/utils/localStorage'

import useIsDarkMode from './useIsDarkMode'
import useFeatureSwitch from './useFeatureSwitch'

const useShouldShowDarkModeAdoption = () => {
  const isDarkModeAdoptionEnabled = useFeatureSwitch('web_dark_mode_adoption')
  const isDarkMode = useIsDarkMode()

  return (
    isDarkModeAdoptionEnabled &&
    !getLocalStorageItem(IS_DARK_MODE_BANNER_CLOSED_LOCAL_STORAGE_KEY) &&
    !isDarkMode
  )
}

export default useShouldShowDarkModeAdoption
