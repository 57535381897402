'use client'

import { Component } from 'react'
import { injectIntl, IntlShape } from 'react-intl'
import { Button, Card, Cell, Icon } from '@vinted/web-ui'
import { Dropdown16 } from '@vinted/monochrome-icons'

import { SearchBarSearchType } from 'constants/search'

const SEARCH_TYPES: Array<SearchBarSearchType> = [
  SearchBarSearchType.Item,
  SearchBarSearchType.User,
  SearchBarSearchType.Faq,
]
const SELECTOR_DROPDOWN_WIDTH = 150

type Props = {
  isOpen: boolean
  selectedType: SearchBarSearchType
  onClick: (() => void) | null | undefined
  onSelect: ((type: SearchBarSearchType) => void) | null | undefined
  intl: IntlShape
}

class TypeSelector extends Component<Props> {
  static defaultProps = {
    isOpen: false,
    selectedType: SearchBarSearchType.Item,
    onClick: null,
    onSelect: null,
  }

  translate = (id: string) => this.props.intl.formatMessage({ id: `searchbar.${id}` })

  handleButtonClick = () => {
    if (this.props.onClick) this.props.onClick()
  }

  handleTypeClick = (searchType: SearchBarSearchType) => () => {
    if (this.props.onSelect) this.props.onSelect(searchType)
  }

  renderDropdown(searchTypes: Array<SearchBarSearchType>) {
    if (!this.props.isOpen) return null

    return (
      <div
        className="u-ui-margin-top-regular u-position-absolute"
        style={{ width: SELECTOR_DROPDOWN_WIDTH }}
      >
        <Card>
          <div className="u-overflow-hidden">
            {searchTypes.map(type => (
              <Cell
                key={type}
                type={Cell.Type.Navigating}
                styling={Cell.Styling.Narrow}
                onClick={this.handleTypeClick(type)}
                testId={`search-bar-search-type-${type}`}
              >
                {this.translate(`types.${type}`)}
              </Cell>
            ))}
          </div>
        </Card>
      </div>
    )
  }

  render() {
    const searchType = SEARCH_TYPES.find(type => type === this.props.selectedType)

    if (!searchType) return null

    return (
      <div className="u-position-relative">
        <Button
          text={<span id={`search-${searchType}`}>{this.translate(`types.${searchType}`)}</span>}
          styling={Button.Styling.Flat}
          size={Button.Size.Medium}
          theme="muted"
          icon={<Icon name={Dropdown16} color={Icon.Color.GreyscaleLevel3} />}
          iconPosition={Button.IconPosition.Right}
          onClick={this.handleButtonClick}
          testId="search-bar-search-type"
          aria={{
            'aria-expanded': this.props.isOpen,
          }}
        />
        {this.renderDropdown(SEARCH_TYPES)}
      </div>
    )
  }
}

export default injectIntl(TypeSelector)
