'use client'

import { Component, ReactNode } from 'react'

import { Breakpoints } from 'types/components'

import withBreakpoint, { BreakpointProps } from './withBreakpoint'

type Props = BreakpointProps & {
  children: ReactNode
  breakpoint: keyof Breakpoints
}

class ShowAtBreakpoint extends Component<Props> {
  render() {
    const { breakpoints, breakpoint } = this.props
    const matchedBreakpoint = breakpoints[breakpoint]

    return matchedBreakpoint ? this.props.children : null
  }
}

export default withBreakpoint(ShowAtBreakpoint)
