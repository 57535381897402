'use client'

import { ReactNode, useEffect, useState } from 'react'
import { Tabs, Tooltip } from '@vinted/web-ui'

import { getLocalStorageItem, setLocalStorageItem } from 'libs/utils/localStorage'
import useWindowEvent from 'hooks/useWindowEvent'
import useTranslate from 'hooks/useTranslate'
import useAbTest from 'hooks/useAbTest'
import useSession from 'hooks/useSession'
import { Screen } from 'constants/tracking/screens'
import useBreakpoint from 'hooks/useBreakpoint'

import useClientSideOnly from '../../../next/hooks/useClientSideOnly'

const TOOLTIP_DURATION_MS = 5000
const MAX_TOOLTIP_EXPOSE_COUNT = 3

const storageKey = (id: string | undefined) => `electronics-tab-tooltip-expose-count-${id}`
const getExposeCount = (id: string | undefined) => Number(getLocalStorageItem(storageKey(id)))
const getShowTooltip = (id: string | undefined) => getExposeCount(id) < MAX_TOOLTIP_EXPOSE_COUNT

const trackTooltipExpose = (id: string | undefined) => {
  const newCount = getExposeCount(id) + 1

  setLocalStorageItem(storageKey(id), String(newCount))
}

type Props = {
  title: ReactNode
}

const ElectronicsTab = ({ title }: Props) => {
  const isClientSide = useClientSideOnly()
  const translate = useTranslate()

  const isHomePage = useSession().screen === Screen.NewsFeed
  const isDesktop = useBreakpoint().desktops
  const isAbTestAllowed =
    useAbTest({
      abTestName: 'buyer_domain_holdout_2024q4',
    })?.variant !== 'off' &&
    isHomePage &&
    isDesktop

  const isTooltipEnabled =
    useAbTest({
      abTestName: 'electronics_vertical_tooltip',
      shouldTrackExpose: isAbTestAllowed,
      shouldTrackOncePerSessionDay: true,
    })?.variant === 'on' && isAbTestAllowed

  const { anonId } = useSession()
  const [showTooltip, setShowTooltip] = useState(() => isTooltipEnabled && getShowTooltip(anonId))

  const hideTooltip = () => setShowTooltip(false)

  useEffect(() => {
    if (!showTooltip) return undefined

    const timeoutId = setTimeout(hideTooltip, TOOLTIP_DURATION_MS)

    return () => clearTimeout(timeoutId)
  }, [showTooltip])

  useEffect(() => {
    if (showTooltip) trackTooltipExpose(anonId)
  }, [showTooltip, anonId])

  useWindowEvent('click', hideTooltip, { once: true })

  const renderedTitle = <Tabs.TabTitle>{title}</Tabs.TabTitle>

  if (!isClientSide) return renderedTitle
  if (!isTooltipEnabled) return renderedTitle

  return (
    <Tooltip
      content={translate('homepage.electronics_tooltip')}
      placement={Tooltip.Placement.Bottom}
      show={showTooltip}
      hover={false}
    >
      {renderedTitle}
    </Tooltip>
  )
}

export default ElectronicsTab
