import { PageId } from 'types/page-id'

import usePageId from './usePageId'

const useIsInPage = (pageIdentificator: Array<PageId> | PageId) => {
  const pageId = usePageId()

  if (pageId === null) return false

  return [pageIdentificator].flat().includes(pageId)
}

export default useIsInPage
