import { PageId } from 'types/page-id'

export const POLICY_FOOTER_PAGES: Array<PageId> = [
  'checkout',
  'inbox',
  'inbox-message',
  'item-edit',
  'items-new',
  'settings-security-phone-verification',
]

export const INBOX_PAGES: Array<PageId> = ['inbox', 'inbox-message']

export const NO_SPACER_BEFORE_MAIN_FOOTER_PAGES: Array<PageId> = [
  'about',
  'how-it-works',
  'influencer-landing-page',
  'no-changes-to-taxes',
  'pro',
]

export const LAYOUT_BREAKOUT_PAGES: Array<PageId> = [
  'app',
  'checkout',
  'how-it-works',
  'referrals',
  'referrals-registrations',
  'root',
]

export const NON_WHITE_BACKGROUND_PAGES: Array<PageId> = [
  'checkout',
  'common-text',
  'complaint',
  'complaint-faq-entry',
  'delete-account',
  'feedback',
  'item',
  'item-edit',
  'items-new',
  'member-balance-activation',
  'member-edit-feedback',
  'member-general-change-forgot-password',
  'member-general-change-password',
  'member-general-delete-account',
  'member-new-feedback',
  'member-notifications',
  'member-personalization-brands',
  'member-personalization-sizes-by-category',
  'member-satisfaction-survey',
  'member-settings-new-email',
  'privacy-policy',
  'private-beta-terms-of-use',
  'pro-terms-and-conditions',
  'pro-terms-of-use',
  'request-data-export',
  'settings-account',
  'settings-account-staff',
  'settings-bundle-discount',
  'settings-donations',
  'settings-epr',
  'settings-payments',
  'settings-policies',
  'settings-privacy',
  'settings-profile',
  'settings-security',
  'settings-security-payments-identity',
  'settings-security-payments-identity-mobile',
  'settings-security-phone-verification',
  'settings-security-sessions',
  'settings-shipping',
  'settings-subscriptions',
  'settings-tax-rules',
  'settings-taxpayer',
  'settings-taxpayer-education',
  'settings-taxpayer-report',
  'settings-taxpayer-summary',
  'taxpayers-special-verification-form',
  'wallet-balance',
  'wallet-balance-donate',
  'wallet-bank-account',
  'wallet-history',
  'wallet-history-by-year-month',
  'wallet-history-overview',
  'wallet-income-reports',
  'wallet-invoices',
  'wallet-new-payout',
  'wallet-payout',
  'wallet-setup',
]
