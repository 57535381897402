export enum DisplayType {
  List = 'list',
  ListSearch = 'list_search',
  Grid = 'grid',
  HybridPrice = 'hybrid_price',
}

export enum SelectionType {
  Single = 'single',
  Multi = 'multi',
}

export enum OptionType {
  Root = 'root',
  Default = 'default',
  Navigational = 'navigational',
  Group = 'group',
}

export enum OptionExtentionType {
  Color = 'color',
  Image = 'image',
}

export enum PrefixImageSize {
  Regular = 'regular',
  Medium = 'medium',
}

export enum FilterModalNames {
  Default = '',
  Root = 'root',
  Filter = 'filter',
}
