'use client'

import Script from 'next/script'

import { ConsentGroup } from 'constants/consent'
import useIsConsentGroupEnabled from 'hooks/useIsConsentGroupEnabled'

const Confiant = () => {
  const isTargetingConsentGroupEnabled = useIsConsentGroupEnabled(ConsentGroup.Targeting)

  if (!isTargetingConsentGroupEnabled) return null

  return (
    <Script
      id="confiant-script"
      data-testid="confiant-script"
      src="https://cdn.confiant-integrations.net/BvGg6QCjGuFUb4-LOhdNdnITfpg/gpt_and_prebid/config.js"
      strategy="lazyOnload"
    />
  )
}

export default Confiant
