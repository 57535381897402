'use client'

import { Suspense, useCallback, useEffect, useState } from 'react'

import { AdKind } from 'constants/ads'
import { ConsentGroup } from 'constants/consent'
import { AdsPlacementModel } from 'types/models'
import useIsConsentGroupEnabled from 'hooks/useIsConsentGroupEnabled'

import RtbAdvertisement from '../RtbAdvertisement'
import useAdEventTracking from '../useAdEventTracking'
import AdInfo from '../AdInfo'
import VanAdvertisement from '../VanAdvertisement'

type Props = {
  id: string
  placementConfig: AdsPlacementModel
  isManuallyRefreshed: boolean
  isManuallyRendered: boolean
  onAdRender: (isAdVisible: boolean) => void
  isAdRendered: boolean
}

const AdContent = ({
  id,
  placementConfig,
  isManuallyRefreshed,
  isManuallyRendered,
  onAdRender,
  isAdRendered,
}: Props) => {
  const [shouldRenderAdContent, setShouldRenderAdContent] = useState(false)
  const isConsentGroupEnabled = useIsConsentGroupEnabled(ConsentGroup.Targeting)

  const { viewableImpressionRef, impressionRef } = useAdEventTracking({
    isAdRendered,
    placementConfig,
  })

  useEffect(() => {
    setShouldRenderAdContent(isConsentGroupEnabled)
  }, [isConsentGroupEnabled])

  const renderAdByKind = useCallback(() => {
    if (placementConfig.kind === AdKind.Van) {
      return <VanAdvertisement id={id} config={placementConfig} onAdRender={onAdRender} />
    }

    if (placementConfig.kind === AdKind.Rtb) {
      return (
        <RtbAdvertisement
          id={id}
          config={placementConfig}
          isManuallyRendered={isManuallyRendered}
          isManuallyRefreshed={isManuallyRefreshed}
          onAdRender={onAdRender}
        />
      )
    }

    return null
  }, [placementConfig, id, onAdRender, isManuallyRendered, isManuallyRefreshed])

  return (
    <Suspense>
      <div className="ad-content" suppressHydrationWarning>
        {isAdRendered && <AdInfo />}
        {shouldRenderAdContent && (
          <div ref={viewableImpressionRef}>
            <div ref={impressionRef}>{renderAdByKind()}</div>
          </div>
        )}
      </div>
    </Suspense>
  )
}

export default AdContent
