'use client'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { noop } from 'lodash'

import AdManager from 'libs/common/ad-manager'

import useIsConsentBannerLoaded from 'hooks/useIsConsentBannerLoaded'
import useTranslate from 'hooks/useTranslate'

import { RtbPlacementConfigModel } from 'types/models'

import { AdPlatform, AdShape } from 'constants/ads'

import useConsent from 'hooks/useConsent'

import useAdRenderedCheck from '../useAdRenderedCheck'
import useAdLoadtimeLogging from '../useAdLoadtimeLogging'
import useRequestedAdLogging from '../useRequestedAdLogging'
import { getAdPlacementId } from '../utils'

type Props = {
  id: string
  config: RtbPlacementConfigModel
  isManuallyRendered?: boolean
  isManuallyRefreshed?: boolean
  onAdRender?: (isAdVisible: boolean) => void
}

const RtbAdvertisement = ({
  id,
  config,
  isManuallyRendered = false,
  isManuallyRefreshed = false,
  onAdRender = noop,
}: Props) => {
  const placementRegisteredRef = useRef(false)
  const [adDivElement, setAdDivElement] = useState<HTMLDivElement | null>(null)

  const translate = useTranslate('advertisements')

  const { isCookieConsentVersion } = useConsent()

  const isRefreshEnabled = useMemo(
    () => config.platform === AdPlatform.Web || config.shape === AdShape.Leaderboard,
    [config.shape, config.platform],
  )

  const isConsentBannerLoaded = useIsConsentBannerLoaded()
  const placementId = getAdPlacementId(config)
  const { onRequest: onAdLoadtimeRequest, onLoad: onAdLoadtimeLoad } = useAdLoadtimeLogging({
    shape: config.shape,
    page: config.page,
    platform: config.platform,
    countryCode: config.countryCode,
  })
  const { onRequest: onRequestedAdRequest } = useRequestedAdLogging(placementId)

  const handleOnPlacementLoad = useCallback(
    (event: googletag.events.SlotOnloadEvent) => {
      if (!event.slot) return

      onAdLoadtimeLoad()
    },
    [onAdLoadtimeLoad],
  )

  const handleOnPlacementRequest = useCallback(() => {
    onAdLoadtimeRequest()
    onRequestedAdRequest()
  }, [onAdLoadtimeRequest, onRequestedAdRequest])

  const registerPlacement = useCallback(() => {
    if (placementRegisteredRef.current) return

    AdManager.registerPlacement({
      placementConfig: config,
      id,
      onPlacementLoad: handleOnPlacementLoad,
      onPlacementRequest: handleOnPlacementRequest,
      isRefreshEnabled,
      isManuallyRendered,
      isManuallyRefreshed,
      iframeTitle: translate('advertisement'),
    })

    placementRegisteredRef.current = true
  }, [
    config,
    id,
    isManuallyRendered,
    isManuallyRefreshed,
    isRefreshEnabled,
    translate,
    handleOnPlacementLoad,
    handleOnPlacementRequest,
  ])

  const handleAdRender = useCallback(
    (isAdVisible: boolean) => {
      onAdRender(isAdVisible)
    },
    [onAdRender],
  )

  useAdRenderedCheck({ targetElement: adDivElement, onAdRender: handleAdRender })

  useEffect(() => {
    if (!adDivElement) return

    const isConsentValuesLoaded = isCookieConsentVersion && isConsentBannerLoaded

    if (isConsentValuesLoaded || !isCookieConsentVersion) {
      registerPlacement()
    }
  }, [adDivElement, registerPlacement, isCookieConsentVersion, isConsentBannerLoaded])

  useEffect(() => {
    return () => {
      if (!placementRegisteredRef.current) return

      AdManager.unregisterPlacement(id)
    }
  }, [id])

  return <div data-testid="rtb-advertisement" id={id} ref={setAdDivElement} />
}

export default RtbAdvertisement
