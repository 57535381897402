import {
  ACTIVITY_EVENTS,
  AD_VISIBILITY_RATIO,
  DEBOUNCE_TIME,
  REFRESH_AD_TIME,
  USER_INACTIVE_TIME,
  BidderState,
  Bidder,
  AdPlatform,
  GoogleEventName,
} from 'constants/ads'

import { serverSide } from 'libs/utils/environment'
import { isMobile } from 'libs/utils/userAgentParser'

import {
  RefreshManager,
  LocationManager,
  RequestManager,
  IndividualRequestManager,
  RegisterPlacementProps,
} from './types'
import {
  sendAdserverRequest,
  fetchAmazonBids,
  fetchPrebidBids,
  setupAdPlacement,
  setupGoogleSlot,
  getPlacementById,
} from './utils'

const AdManager = (() => {
  let adCount = 0

  const refreshManager: RefreshManager = {
    isOn: false,
    lastActionTimestamp: Date.now(),
    refreshInterval: undefined,
    debounceTimer: undefined,
  }

  const locationManager: LocationManager = {
    isLocationChangeEventSetup: false,
    lastHref: serverSide ? '/' : window.location.href,
  }

  const requestManager: RequestManager = Object.values(Bidder).reduce(
    (acc, bidder) => ({ ...acc, [bidder]: BidderState.Idle }),
    {},
  )

  const individualRequestManager: IndividualRequestManager = {}

  function areAllBidsBack(placementId: string) {
    const bidders = Object.values(Bidder)
    const backStates = [
      BidderState.Fetched,
      BidderState.TimedOut,
      BidderState.BadSizes,
      BidderState.NoBids,
    ]
    const placementRequestManager = individualRequestManager[placementId]

    if (!placementRequestManager) return false

    return (
      bidders
        .map(bidder => placementRequestManager[bidder])
        .filter(bidderState => backStates.includes(bidderState)).length === bidders.length
    )
  }

  function headerBidderBack(bidder: Bidder, placementId: string, state: BidderState) {
    let placementRequestManager = individualRequestManager[placementId]

    if (!placementRequestManager) {
      placementRequestManager = { ...requestManager }
    }

    placementRequestManager[bidder] = state

    individualRequestManager[placementId] = placementRequestManager

    const areBidsBack = areAllBidsBack(placementId)

    if (areBidsBack) {
      sendAdserverRequest(placementId, placementRequestManager)
    }
  }

  function requestHeaderBidding(id: string, refresh = false) {
    fetchAmazonBids(id, headerBidderBack, refresh)
    fetchPrebidBids(id, headerBidderBack, refresh)
  }

  function isAdVisible(node?: HTMLElement) {
    if (!node) return false

    const boundaries = node.getBoundingClientRect()

    if (!boundaries) return false

    const offset = boundaries.height * AD_VISIBILITY_RATIO
    const isVisible = window.innerHeight > boundaries.top + offset && boundaries.bottom > offset

    return isVisible
  }

  function isUserActive() {
    const inactiveDuration = (Date.now() - refreshManager.lastActionTimestamp) / 1000

    return !document.hidden && inactiveDuration < USER_INACTIVE_TIME
  }

  function refreshAd(placementId) {
    const placement = getPlacementById(placementId)

    if (!placement || !individualRequestManager[placementId]) return

    individualRequestManager[placementId] = { ...requestManager }

    requestHeaderBidding(placementId, true)

    placement.activeDuration = 0
  }

  function handleRefreshTick() {
    if (!window.adPlacements) return
    if (!isUserActive()) return

    Object.keys(window.adPlacements)
      .filter(placementId => {
        const placement = getPlacementById(placementId)

        if (!placement) return false

        const { isManuallyRefreshed, isRefreshEnabled } = placement

        if (isRefreshEnabled && !isManuallyRefreshed) return true

        return false
      })
      .forEach(placementId => {
        const placement = getPlacementById(placementId)

        if (!placement) return

        const { isOutOfPageAd, node, activeDuration } = placement

        const isRefreshAvailable = isOutOfPageAd || isAdVisible(node)

        if (!isRefreshAvailable) return

        if (activeDuration >= REFRESH_AD_TIME) {
          refreshAd(placementId)
        }

        placement.activeDuration += 1
      })
  }

  function handleActivityEvent() {
    refreshManager.lastActionTimestamp = Date.now()
  }

  function handleLocationChange() {
    if (
      window.location.href !== locationManager.lastHref &&
      !!window.adPlacements &&
      !refreshManager.debounceTimer
    ) {
      refreshManager.debounceTimer = setTimeout(() => {
        refreshManager.debounceTimer = undefined
      }, DEBOUNCE_TIME)

      Object.keys(window.adPlacements).forEach(placementId => {
        const placement = getPlacementById(placementId)

        if (!placement) return

        const { node, isOutOfPageAd } = placement

        if (!isOutOfPageAd && !node) return

        refreshAd(placementId)
      })
    }

    locationManager.lastHref = window.location.href
  }

  function setupUrlChangeHandler() {
    if (locationManager.isLocationChangeEventSetup || !('Proxy' in window)) return

    window.history.pushState = new Proxy(window.history.pushState, {
      apply: (target, thisArg, argArray: [any, string, string | URL | null | undefined]) => {
        window.dispatchEvent(new Event('locationchange'))

        return target.apply(thisArg, argArray)
      },
    })

    window.addEventListener('locationchange', handleLocationChange)

    locationManager.isLocationChangeEventSetup = true
  }

  function setupRefresh() {
    refreshManager.isOn = true
    refreshManager.refreshInterval = setInterval(handleRefreshTick, 1000)

    ACTIVITY_EVENTS.forEach(event => window.addEventListener(event, handleActivityEvent))

    setupUrlChangeHandler()
  }

  function stopAdRefresh() {
    if (refreshManager.refreshInterval) clearInterval(refreshManager.refreshInterval)

    refreshManager.isOn = false

    ACTIVITY_EVENTS.forEach(event => window.removeEventListener(event, handleActivityEvent))

    window.removeEventListener('locationchange', handleLocationChange)
  }

  function generatePlacementId(): string {
    adCount += 1

    return `adplacement-${adCount}`
  }

  function unregisterPlacement(placementId: string) {
    const { adPlacements } = window

    if (!adPlacements) return

    const adPlacement = adPlacements[placementId]

    if (!adPlacement) return

    const { googletag } = window

    if (googletag && adPlacement.googleSlot) {
      const slot = adPlacement.googleSlot

      googletag.cmd.push(() => {
        googletag.destroySlots([slot])
      })
    }

    delete adPlacements[placementId]
    delete individualRequestManager[placementId]

    if (!Object.keys(adPlacements).length) stopAdRefresh()
  }

  function registerPlacement({
    id,
    placementConfig,
    onPlacementLoad,
    onPlacementRenderEnded,
    onPlacementRequest,
    onImpressionViewable,
    isRefreshEnabled,
    isManuallyRendered = false,
    isManuallyRefreshed = false,
    iframeTitle,
  }: RegisterPlacementProps) {
    const node = document.getElementById(id)

    if (!node) return

    setupAdPlacement({
      id,
      placementConfig,
      node,
      isManuallyRendered,
      isManuallyRefreshed,
      isRefreshEnabled,
    })

    setupGoogleSlot(
      id,
      {
        [GoogleEventName.SlotOnload]: onPlacementLoad,
        [GoogleEventName.SlotRequested]: onPlacementRequest,
        [GoogleEventName.SlotRenderEnded]: onPlacementRenderEnded,
        [GoogleEventName.ImpressionViewable]: onImpressionViewable,
      },
      iframeTitle,
    )

    requestHeaderBidding(id)

    if (!refreshManager.isOn && isRefreshEnabled) setupRefresh()
  }

  function getAdsPlatform(userAgent: string) {
    return isMobile(userAgent) ? AdPlatform.Mobile : AdPlatform.Web
  }

  function sendManualAdRequest(placementId: string) {
    const placement = getPlacementById(placementId)

    if (!placement) return

    googletag.cmd.push(() => {
      if (placement.googleSlot) googletag.pubads().refresh([placement.googleSlot])
    })
  }

  return {
    generatePlacementId,
    unregisterPlacement,
    registerPlacement,
    getAdsPlatform,
    sendManualAdRequest,
  }
})()

export default AdManager
