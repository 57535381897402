'use client'

import { MouseEvent } from 'react'
import { Cell, Text } from '@vinted/web-ui'

type Props = {
  title: string
  url: string
  onClick?: (event: MouseEvent) => void
  testId?: string
}

const LinkCell = ({ title, url, onClick, testId }: Props) => (
  <Cell
    type={Cell.Type.Navigating}
    styling={Cell.Styling.Narrow}
    url={url}
    onClick={onClick}
    body={<Text as="span">{title}</Text>}
    testId={testId}
  />
)

export default LinkCell
